import React from "react";
// mui
import { Box, Stack, Tooltip, Typography } from "@mui/material";
// icons
import { MdAccessTime } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi2";
import { MdOutlineAlternateEmail } from "react-icons/md";
// moment
import moment from "moment";
import { Link } from "react-router-dom";
const UsersNotificationCard = ({ notificationDetails }) => {
    const { title, body, created_at, topic, user } = notificationDetails;
    return (
        <Stack
            sx={{
                boxShadow: 1,
                borderRadius: 1,
                p: 2,
                border: "1px solid #f1f1f1",
                minWidth: { xs: "290px", sm: "350px" },
                flex: 1,
            }}
            component={Link}
            to={`/show-users-notifications/${notificationDetails.id}`}
        >
            <Box>
                <Typography variant="h6">{title}</Typography>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="10px"
                >
                    <Tooltip title="تارخ انشاء الاشعار" arrow>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: "#333",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: 1,
                            }}
                        >
                            <MdAccessTime />{" "}
                            {moment(created_at).format("YYYY/MM/DD HH:mm")}
                        </Typography>
                    </Tooltip>

                    <Tooltip title="المتسخدم" arrow>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: "#333",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px",
                                marginBottom: 1,
                            }}
                        >
                            <MdOutlineAlternateEmail />{" "}
                            {user?.name || user?.email || user?.phone}
                        </Typography>
                    </Tooltip>
                </Box>
                <Typography variant="body1">{body}</Typography>
            </Box>
        </Stack>
    );
};

export default UsersNotificationCard;
