import { useEffect, useState } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// formik
import { useFormik } from "formik";
// mui
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Stack,
    Typography,
    Divider,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
// componetns
import { RingsLoader, SubmitButton } from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts";
import { MdAccountBalanceWallet } from "react-icons/md";

const DriverChangeCash = ({
    openChargeCashForm,
    setOpenChargeCashForm,
    driver,
    setRerender,
}) => {
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(false);
    const [driverTrxId, setDriverTrxId] = useState("");
    // ---------- charge_cash_form_toggelers_functions ----------
    const handleOpenDialog = () => {
        setOpenChargeCashForm(true);
    };
    const handleCloseDialog = () => {
        setOpenChargeCashForm(false);
    };
    // ---------- submit_form ----------
    const onSubmit = (values) => {
        // setSubmitting(false);
        EnhancedAxios("application/json", userToken)
            .post(`/admin/driver-wallet-transactions`, values)
            .then((res) => {
                successAlert(`تم تغيير رصيد السائق`);
                setSubmitting(false);
                resetForm();
                setRerender(Math.random());
                handleCloseDialog();
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
        // console.log(values);
    };
    // ---------- form_configration ----------
    const {
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            notes: "",
            type: "deposit",
            amount: "",
            payment_method: "",
            user: {
                id: driver?.id,
            },
            is_confirmed: true,
        },
        onSubmit,
    });
    // ---------- page_ui ----------

    return (
        <Dialog
            open={openChargeCashForm}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>عملية تعديل رصيد للسائق </DialogTitle>
            {loading ? (
                <RingsLoader />
            ) : (
                <>
                    {/* <DialogContent>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            gap="20px"
                            flexWrap="wrap"
                        >
                            <Stack>
                                <Typography variant="subtitle2">
                                    الرصيد المبدئي
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.starting_charge || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    الرصيد الحالي
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.credit || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    قيمة طلبات السائق النقدية
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.total_cash || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    قيمة طلبات السائق الرقمية
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.total_digital || 0}
                                </Typography>
                            </Stack>
                            <Divider flexItem orientation="vertical" />
                            <Stack>
                                <Typography variant="subtitle2">
                                    أرباح السائق
                                </Typography>
                                <Typography variant="h6">
                                    {driver?.driver_trx?.profit}
                                </Typography>
                            </Stack>
                        </Box>
                    </DialogContent>
                    <Divider /> */}
                    <DialogContent>
                        <Stack
                            component="form"
                            onSubmit={handleSubmit}
                            gap="20px"
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gap="20px"
                                flexWrap="wrap"
                                py={1}
                                width="100%"
                                sx={{
                                    flexDirection: { xs: "column", md: "row" },
                                    "& > *": {
                                        flex: "1",
                                        width: { xs: "100%", md: "auto" },
                                    },
                                }}
                            >
                                <FormControl fullWidth>
                                    <InputLabel id="type-select-label">
                                        نوع العملية
                                    </InputLabel>
                                    <Select
                                        labelId="type-select-label"
                                        id="type-select"
                                        value={values.type}
                                        onChange={handleChange}
                                        name="type"
                                        onBlur={handleBlur}
                                        label="نوع العملية"
                                    >
                                        <MenuItem value="deposit">
                                            ايداع
                                        </MenuItem>
                                        <MenuItem value="withdraw">
                                            سحب
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    autoFocus
                                    name="payment_method"
                                    label="طريقة الدفع"
                                    value={values.payment_method}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                />
                                <TextField
                                    autoFocus
                                    name="amount"
                                    label="قيمة المبلغ"
                                    value={values.amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                />
                                <TextField
                                    name="notes"
                                    label="ملاحظات"
                                    value={values.notes}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="is_confirmed"
                                        checked={values.is_confirmed}
                                        onChange={handleChange}
                                    />
                                }
                                label="مؤكدة"
                            />
                            <SubmitButton
                                text="تأكيد"
                                disabled={isSubmitting}
                            />
                        </Stack>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default DriverChangeCash;
