import { useState } from "react";
// router
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// redux
import { useSelector } from "react-redux";
// formik
import { useFormik } from "formik";
// mui
import {
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Select,
    MenuItem,
    Box,
    FormControl,
    InputLabel,
    Switch,
} from "@mui/material";
// components
import {
    SubmitButton,
    GenderSelect,
    RingsLoader,
    CitySelect,
} from "../../components";
import { ErrorsStack, UploadImagePreview } from "../../sections";
// scheme
import { editDriverSchema } from "../../schemes/drivers/editDriverSchema";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts";
import { getUserRole } from "../../utils/roles/getUserRole";

const EditDriverForm = ({
    img,
    name,
    phone,
    email,
    is_disabled,
    nationality,
    has_second_job,
    car_model,
    car_type,
    car_year,
    birthdate,
    city,
    gender,
}) => {
    const { driverId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const [file, setFile] = useState(null);
    const role = getUserRole(userData);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        const data = { ...values };
        if (data.password === "") {
            delete data["password"];
            delete data["password_confirmation"];
        }
        const formData = new FormData();
        if (typeof data["city"] == "string") {
            data["city"] = JSON.stringify({ id: data["city"] });
        } else {
            data["city"] = JSON.stringify(data["city"]);
        }

        for (let key in data) {
            formData.append(key, data[key]);
        }

        EnhancedAxios("multipart/form-data", userToken)
            .put(`/${role}/users/${driverId}`, data)
            .then((res) => {
                successAlert("تم تعديل بيانات السائق");
                setSubmitting(false);
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            img: img,
            name: name,
            phone: phone,
            email: email,
            is_disabled: is_disabled,
            has_second_job: has_second_job,
            nationality: nationality,
            gender: gender,
            city: city?.id,
            car_type: car_type,
            car_year: car_year,
            car_model: car_model,
            password: "",
            password_confirmation: "",
            birthdate: birthdate,
        },
        validationSchema: editDriverSchema,
        onSubmit,
    });
    return (
        <Stack spacing={1} component="form" onSubmit={handleSubmit}>
            <Typography variant="h6">البيانات الشخصية</Typography>
            <UploadImagePreview
                file={file}
                setFile={setFile}
                defaultImage={img}
                setValues={setValues}
            />
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "20px",
                    "& > .MuiFormControl-root, & > .MuiAutocomplete-root": {
                        width: "50%",
                        flex: "1",
                        minWidth: "290px",
                    },
                    "& .MuiInputBase-root": { width: "100%" },
                    "& .MuiOutlinedInput-input": { width: "100%" },
                }}
            >
                <TextField
                    type="text"
                    name="name"
                    label="اسم المستخدم"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    type="tel"
                    name="phone"
                    label="رقم الهاتف"
                    value={values.phone ?? "لا يوجد"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    type="email"
                    name="email"
                    label="البريد الالكترونى"
                    value={values.email ?? "لا يوجد"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <GenderSelect values={values} setValues={setValues} />
                <CitySelect setValues={setValues} values={values} />
                <TextField
                    type="date"
                    name="birthdate"
                    label="تاريخ الميلاد"
                    value={values.birthdate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    type="text"
                    name="nationality"
                    label="الجنسية"
                    value={values.nationality ?? "لا يوجد"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <FormControl fullWidth>
                    <InputLabel>لديه وظيفة اخرى</InputLabel>
                    <Select
                        name="has_second_job"
                        value={values.has_second_job}
                        label="لديه وظيفة اخرى"
                        onChange={handleChange}
                    >
                        <MenuItem value={true}>نعم</MenuItem>
                        <MenuItem value={false}>لا</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    type="text"
                    name="car_type"
                    label="نوع السيارة"
                    value={values.car_type ?? "لا يوجد"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    type="number"
                    name="car_year"
                    label="سنة انتاج السيارة"
                    value={values.car_year ?? 0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    type="text"
                    name="car_model"
                    label="موديل السيارة"
                    value={values.car_model ?? "لا يوجد"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    type="password"
                    name="password"
                    label="تعديل الرقم السري"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    type="password"
                    name="password_confirmation"
                    label="تاكيد تعديل الرقم السري"
                    value={values.password_confirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={!values.is_disabled}
                        onChange={(e) =>
                            setValues((prev) => ({
                                ...prev,
                                is_disabled: !e.target.checked,
                            }))
                        }
                        name="is_disabled"
                    />
                }
                label="تفعيل"
            />
            <SubmitButton text="تعديل" disabled={isSubmitting} />
            <ErrorsStack errors={errors} touched={touched} />
        </Stack>
    );
};

export default EditDriverForm;
