import { LinkButton, ToggleStatus } from "../../components";
import { Link } from "react-router-dom";
import { translateToArabic } from "../translate/englishToArabic";
import moment from "moment";

export const operatorCols = [
    {
        field: "name",
        headerName: "اسم المستخدم",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? "غير معروف",
    },
    {
        field: "email",
        headerName: "البريد الالكتروني",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.email ?? "لايوجد",
    },

    {
        field: "created_at",
        headerName: "تاريخ الانشاء",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format(`YYYY/MM/DD-hh:mm`)
                : "لا يوجد",
    },
    {
        field: "toggleStatus",
        headerName: "الحالة",
        width: "70",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <ToggleStatus
                id={params.row.id}
                is_disabled={params.row.is_disabled}
                putApiUrl={`admin/users/${params.row.id}`}
            />
        ),
    },
    {
        field: "edit",
        headerName: "تعديل",
        width: "100",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <LinkButton
                text="تعديل"
                to={`/edit-operator/${params.row?.id}`}
                size="small"
                variant="outlined"
            />
        ),
    },
];
