import React, { useState, useEffect, useRef, useCallback } from "react";
// google maps
import {
    GoogleMap,
    DrawingManager,
    useLoadScript,
    Marker,
    Polygon,
} from "@react-google-maps/api";
const polygonOptions = {
    fillColor: "#00000050",
    fillOpacity: 0.5,
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 1,
    zIndex: 2,
};

const DrawingOnMap = ({
    values,
    setValues,
    resetDrawOnMap,
    setResetDrawOnMap,
}) => {
    const [drawingMode, setDrawingMode] = useState("polygon");
    const [polygonPaths, setPolygonPaths] = useState([]);
    const polygonRef = useRef(null); // ref to assign polygon to the map
    const listenersRef = useRef([]); // to handle events on the polygon
    const [center, setCenter] = useState({
        lat: 32.1145535,
        lng: 20.0641798,
    });
    const { isLoaded, error } = useLoadScript({
        libraries: ["drawing"],
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    }); // script loader for map
    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current.getPath().getArray();

            setPolygonPaths(nextPath);
            convertCoordsLatLngObjToArr(
                setValues,
                nextPath.map((latLng) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                })
            );
        }
    }, []);
    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        (polygon) => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach((lis) => lis.remove());
        polygonRef.current = null;
    }, []);
    const onPolygonComplete = (polygon) => {
        const paths = polygon.getPath().getArray();
        setPolygonPaths(paths);
        setDrawingMode(null);
        polygon.setPath([]);
        convertCoordsLatLngObjToArr(
            setValues,
            paths.map((coord) => ({
                lat: coord.lat(),
                lng: coord.lng(),
            }))
        );
    };

    const onRemovePoint = (index) => {
        // Remove the point at the specified index from the polygon points
        const updatedPoints = polygonPaths.filter((_, i) => i !== index);
        setPolygonPaths(updatedPoints);
        convertCoordsLatLngObjToArr(
            setValues,
            updatedPoints.map((coord) => ({
                lat: coord.lat(),
                lng: coord.lng(),
            }))
        );
    };
    // reset the draw
    useEffect(() => {
        if (resetDrawOnMap) {
            setPolygonPaths([]);
            setDrawingMode("polygon");
            setValues((prev) => ({
                ...prev,
                geom: { type: "Polygon", coordinates: [] },
            }));
            setResetDrawOnMap(false)
        }
    }, [resetDrawOnMap]);
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{ height: "400px", width: "100%" }}
            center={center}
            zoom={8}
        >
            <DrawingManager
                drawingMode={drawingMode}
                onPolygonComplete={onPolygonComplete}
                options={{
                    drawingControl: true,
                    drawingControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: ["polygon"],
                    },
                    polygonOptions,
                }}
            />
            {polygonPaths.length ? (
                polygonPaths.length > 0 && (
                    <Polygon
                        paths={polygonPaths}
                        options={polygonOptions}
                        editable={true}
                        draggable={true}
                        onMouseUp={onEdit}
                        onDragEnd={onEdit}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    />
                )
            ) : (
                <></>
            )}
            {polygonPaths.length ? (
                polygonPaths.map((path, index) => (
                    <Marker
                        key={index}
                        position={{ lat: path.lat(), lng: path.lng() }}
                        onRightClick={() => onRemovePoint(index)}
                    />
                ))
            ) : (
                <></>
            )}
        </GoogleMap>
    ) : (
        <></>
    );
};

export default DrawingOnMap;
const convertCoordsArrToLatLngObj = (coords) => {
    let result = [];
    if (coords.length) {
        coords[0].map((point) => {
            const obj = { lat: point[1], lng: point[0] };
            result.push(obj);
        });
    }
    return result;
};

const convertCoordsLatLngObjToArr = (setValues, coords) => {
    let result = [];
    coords.forEach((el) => {
        const smArr = [el.lng, el.lat];
        result.push(smArr);
    });
    setValues((prev) => ({
        ...prev,
        geom: {
            type: "Polygon",
            coordinates: [result],
        },
    }));
    // return result;
};
