import React from "react";
import { Box, Pagination } from "@mui/material";

const CustomPagination = ({
    totalItems,
    itemsPerPage,
    currentPage,
    setCurrentPage,
}) => {
    // ------------------------------------------- pagination_page_changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        setCurrentPage(newPage);
    };
    return (
        <Box width="100%" display="flex" justifyContent="center">
            {totalItems > itemsPerPage && (
                <Pagination
                    defaultPage={1}
                    page={currentPage}
                    count={Math.ceil(totalItems / itemsPerPage)}
                    onChange={handlePageChanging}
                    variant="contained"
                    color="primary"
                    showFirstButton
                    showLastButton
                />
            )}
        </Box>
    );
};

export default CustomPagination;
