import { LinkButton, ToggleStatus } from "../../components";
import { Link } from "react-router-dom";
import { translateToArabic } from "../translate/englishToArabic";
import moment from "moment";

export const prepaidCardsCols = [
    {
        field: "serial_number",
        headerName: "رقم الكارد",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.serial_number ?? "غير معروف",
    },
    {
        field: "value",
        headerName: "القيمة",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.value ?? "غير معروف",
    },
    {
        field: "is_redeemed",
        headerName: "تم استخدامه",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => (params.row?.is_redeemed ? "✅" : "❌"),
    },
    {
        field: "created_at",
        headerName: "تاريخ الانشاء",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format(`YYYY/MM/DD-hh:mm`)
                : "لا يوجد",
    },
];
