import React from "react";
import { IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
const BackButton = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    return (
        <IconButton
            onClick={goBack}
            sx={{ marginRight: "auto", width: "64px", height: "64px" }}
        >
            <GoArrowRight />
        </IconButton>
    );
};

export default BackButton;
