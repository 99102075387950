import React, { useState } from "react";
// mui
import { Button } from "@mui/material";
// icons
import { MdOutlineDelete } from "react-icons/md";
// alerts
import {
    askForConfirmationAlert,
    successAlert,
    errorAlert,
} from "../../utils/alerts";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// react redux
import { useSelector } from "react-redux";

const DeleteUser = ({ id, name }) => {
    const userToken = useSelector((state) => state.userToken);
    const [isDeleted, setIsDeleted] = useState(false);
    const deleteUser = () => {
        EnhancedAxios(null, userToken)
            .delete(`/admin/users/${id}`)
            .then((res) => {
                successAlert("تم حذف المستخدم");
                setIsDeleted(true);
            })
            .catch((error) => errorAlert(error));
    };
    const confirmDeleting = () => {
        askForConfirmationAlert(
            `هل أنت متأكد انك تريد حذف حساب المستخدم ${name}`,
            deleteUser
        );
    };
    return (
        <Button
            variant="outlined"
            color="error"
            onClick={confirmDeleting}
            disabled={isDeleted}
            sx={{ minWidth: "40px", padding: "5px 5px" }}
        >
            <MdOutlineDelete />
        </Button>
    );
};

export default DeleteUser;
