import React from "react";
import { Outlet, Navigate } from "react-router-dom";
// redux staet
import { useSelector } from "react-redux";
import { getUserRole } from "../../utils/roles/getUserRole";
const AllowedTo = ({ roles }) => {
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const role = getUserRole(userData);
    // 2 for admin
    // 7 for operator
    return roles.includes(userData.roles[0]) ? (
        <Outlet />
    ) : (
        // <Navigate to={role === "admin" ? "/" : "/drivers"} />
        <Outlet />
    );
};

export default AllowedTo;
