import moment from "moment";
import { translateToArabic } from "../translate/englishToArabic";
export const transactionsCols = [
    {
        field: "name",
        headerName: "اسم المستخدم",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.user?.name ?? "غير معروف",
    },
    {
        field: "phone",
        headerName: "رقم المستخدم",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.user?.phone ?? "لا يوجد",
    },
    {
        field: "amout",
        headerName: "قيمة الشحن",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.amount ?? "غير معروف",
    },
    {
        field: "type",
        headerName: "نوع العملية",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.type
                ? translateToArabic(params?.row?.type)
                : "غير معروف",
    },
    {
        field: "is_confirmed",
        headerName: "الحالة",
        width: "80",
        sortable: false,
        filterable: false,
        valueGetter: (params) => (params.row?.is_confirmed ? "✅" : "❌ "),
    },
    {
        field: "created_at",
        headerName: "التاريخ",
        width: "150",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD")
                : "غير معروف",
    },
    {
        field: "transactions_notes",
        headerName: "ملاحظات",
        width: "390",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.notes ?? "غير معروف",
    },
];
