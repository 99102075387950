import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// router
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// components
import { BackButton, PageTitle, RingsLoader } from "../../../components";
// mui
import { Stack, Typography } from "@mui/material";
// icons
import {
    MdOutlineEditNotifications,
    MdOutlineNotificationsActive,
    MdOutlineDataObject,
} from "react-icons/md";
import { FaRegCircleUser } from "react-icons/fa6";
const ShowUsersNotifications = () => {
    const userToken = useSelector((state) => state.userToken);
    const [notification, setNotification] = useState(null);
    const [loading, setLoading] = useState(true);
    const { notificationId } = useParams();

    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/notifications/${notificationId}`)
            .then((response) => {
                setNotification(response.data);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    }, []);
    if (loading) return <RingsLoader />;
    return (
        <Stack width="100%" alignItems="center">
            <Stack gap="20px" width="100%" maxWidth="700px">
                <BackButton />
                <PageTitle title="بيانات اشعار المستخدم" />
                <Stack
                    sx={{
                        p: 2,
                        borderRadius: 1,
                        boxShadow: "0px 8px 12px #00000010",
                        color: "#222",
                    }}
                    gap="16px"
                >
                    <Typography
                        variant="body1"
                        display="flex"
                        alignItems="center"
                        gap="16px"
                    >
                        <MdOutlineNotificationsActive size={24} />
                        <span>{notification.title}</span>
                    </Typography>
                    {notification?.body && (
                        <Typography
                            variant="body1"
                            display="flex"
                            alignItems="center"
                            gap="16px"
                        >
                            <MdOutlineEditNotifications size={24} />
                            <span>{notification.body}</span>
                        </Typography>
                    )}
                    <Typography
                        variant="body1"
                        display="flex"
                        alignItems="center"
                        gap="16px"
                    >
                        <FaRegCircleUser size={24} />
                        <span>{notification?.user?.name}</span>
                        <Stack
                            width="8px"
                            height="8px"
                            borderRadius="50%"
                            backgroundColor="#ddd"
                        />
                        <span>{notification?.user?.phone}</span>
                        <Stack
                            width="8px"
                            height="8px"
                            borderRadius="50%"
                            backgroundColor="#ddd"
                        />
                        <span>{notification?.user?.email}</span>
                    </Typography>
                    <Stack direction="row" gap="16px">
                        <MdOutlineDataObject size={24} />
                        <Stack gap="8px">
                            {Object.keys(notification?.data).map((key) => {
                                return (
                                    <Typography variant="body2" key={key}>
                                        {key} : {notification?.data[key]}
                                    </Typography>
                                );
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ShowUsersNotifications;
