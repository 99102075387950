import { useState } from "react";
// react router
import { Outlet } from "react-router-dom";
// mui component
import { Box, IconButton, Stack, Container } from "@mui/material";
// components  and seciton
import { Navbar } from "../../sections";
// icons
import { FaAngleDoubleLeft } from "react-icons/fa";
// links from navbar utils
import { adminLinks } from "../../utils/links/adminLinks";
import { useSelector } from "react-redux";
import { operatorLinks } from "../../utils/links/operatorLinks";

const AdminLayout = () => {
    const [navbarOpen, setNavbarOpen] = useState(true);
    const userData = useSelector((state) => state.userData);
    console.log(userData);
    return (
        <Box display="flex" flexWrap="wrap" height="100vh">
            <Navbar
                navbarOpen={navbarOpen}
                setNavbarOpen={setNavbarOpen}
                links={
                    userData?.roles?.includes(2)
                        ? adminLinks
                        : userData?.roles?.includes(7)
                        ? operatorLinks
                        : []
                }
            />
            <Box
                sx={{ flex: "1", maxHeight: "100vh", overflow: "auto" }}
                className="hide-scrollbar"
            >
                <Container sx={{ height: "100%" }}>
                    <Stack spacing={2} py={4}>
                        <IconButton
                            onClick={() => setNavbarOpen(true)}
                            sx={{
                                width: "50px",
                                height: "50px",
                                display: { xs: "flex", md: "none" },
                            }}
                        >
                            <FaAngleDoubleLeft />
                        </IconButton>

                        <Outlet />
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default AdminLayout;
