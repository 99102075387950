import { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, TextField, Typography, Divider } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    UserLocation,
    TripPath,
} from "../../../components";
// alerts
import { errorAlert } from "../../../utils/alerts";
// moment
import moment from "moment";
import { translateToArabic } from "../../../utils/translate/englishToArabic";
import ChangeTripStatus from "../../../components/select/ChangeTripStatus";
import { getUserRole } from "../../../utils/roles/getUserRole";
const OrderDetails = () => {
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const { orderId } = useParams();
    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState({});
    const role = getUserRole(userData);
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/${role}/trips/${orderId}`)
            .then((res) => {
                setOrderDetails(res.data);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, []);
    if (loading) return <RingsLoader />;
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="تفاصيل الطلب" />
            <Stack spacing="10px" width="100%">
                <Typography variant="subtitle1">بيانات المستخدم</Typography>
                <Box
                    gap="20px"
                    width="100%"
                    paddingBottom="40px"
                    borderBottom="1px solid #aaa"
                    sx={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fill,minmax(300px,1fr))",
                    }}
                >
                    <TextField
                        name="username"
                        label="اسم المستخدم"
                        value={orderDetails.customer.name ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="userphone"
                        label="هاتف المستخدم"
                        value={orderDetails.customer.phone ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="usergender"
                        label="نوع المستخدم"
                        value={
                            orderDetails.customer_gender
                                ? translateToArabic(
                                      orderDetails.customer_gender
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Typography variant="subtitle1">بيانات السائق</Typography>
                <Box
                    gap="20px"
                    width="100%"
                    paddingBottom="40px"
                    borderBottom="1px solid #aaa"
                    sx={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fill,minmax(300px,1fr))",
                    }}
                >
                    <TextField
                        name="driver_name"
                        label="اسم السائق"
                        value={orderDetails.driver?.name ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_email"
                        label="البريد الالكترونى للسائق"
                        value={orderDetails.driver?.email ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_phone"
                        label="هاتف السائق"
                        value={orderDetails.driver?.phone ?? ""}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="drivergender"
                        label="نوع السائق"
                        value={
                            orderDetails.driver_gender
                                ? translateToArabic(orderDetails.driver_gender)
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cartype"
                        label="نوع السيارة"
                        value={
                            orderDetails.car_type
                                ? translateToArabic(orderDetails.car_type)
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                </Box>
                <Typography variant="subtitle1">بيانات الرحلة</Typography>
                <Box
                    gap="20px"
                    width="100%"
                    paddingBottom="40px"
                    borderBottom="1px solid #aaa"
                    sx={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fill,minmax(300px,1fr))",
                    }}
                >
                    <TextField
                        name="friendly_id"
                        label="رقم الرحلة"
                        value={orderDetails.friendly_id}
                        InputProps={{ readOnly: true }}
                    />
                    <ChangeTripStatus
                        value={orderDetails.status}
                        orderId={orderDetails.id}
                    />
                    <TextField
                        name="extended_meters"
                        label="الامتار الاضافية"
                        value={orderDetails.extended_meters}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="total_cost"
                        label="اجمالى التكلفة"
                        value={orderDetails.total_cost}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="created_at"
                        label="تاريخ انشاء الطلب"
                        value={
                            orderDetails.created_at
                                ? moment(orderDetails.created_at).format(
                                      "YYYY/MM/DD -- HH:mm"
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_claimed_at"
                        label="وقت تحرك السائق"
                        value={
                            orderDetails.driver_claimed_at
                                ? moment(orderDetails.driver_claimed_at).format(
                                      "YYYY/MM/DD -- HH:mm"
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_arrived_at"
                        label="وقت وصول السائق"
                        value={
                            orderDetails.driver_arrived_at
                                ? moment(orderDetails.driver_arrived_at).format(
                                      "YYYY/MM/DD -- HH:mm"
                                  )
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_cancelled_at"
                        label="وقت الغاء السائق للطلب"
                        value={
                            orderDetails.driver_cancelled_at
                                ? moment(
                                      orderDetails.driver_cancelled_at
                                  ).format("YYYY/MM/DD -- HH:mm")
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="customer_cancelled_at"
                        label="وقت الغاء الزبون للطلب"
                        value={
                            orderDetails.customer_cancelled_at
                                ? moment(
                                      orderDetails.customer_cancelled_at
                                  ).format("YYYY/MM/DD -- HH:mm")
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="customer_pickup_at"
                        label="وقت  ركوب العميل"
                        value={
                            orderDetails.customer_pickup_at
                                ? moment(
                                      orderDetails.customer_pickup_at
                                  ).format("YYYY/MM/DD -- HH:mm")
                                : ""
                        }
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cost_penalty"
                        label="التكلفة الاضافية"
                        value={orderDetails.cost_penalty ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="driver_path_length"
                        label="طول مسار السائق بالمتر"
                        value={orderDetails.driver_path_length ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="pickup_extended_path_length"
                        label="طول الامتار الاضافية للوصول للزبون بالمتر "
                        value={orderDetails.pickup_extended_path_lenth ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="path_length"
                        label="طول مسار الرحلة بالمتر"
                        value={orderDetails.path_length ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="extended_path_length"
                        label="طول المسار الاضافي بالمتر"
                        value={orderDetails.extended_path_length ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                </Box>

                <Typography variant="subtitle1">بيانات التكلفة</Typography>
                <Box
                    gap="20px"
                    width="100%"
                    paddingBottom="40px"
                    borderBottom="1px solid #aaa"
                    sx={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fill,minmax(300px,1fr))",
                    }}
                >
                    <TextField
                        name="app_profit"
                        label="ارباح التطبيق"
                        value={orderDetails.app_profit ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="area_price"
                        label="سعر المنطقة"
                        value={orderDetails.area_price ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="base_price"
                        label="السعر الاساسي"
                        value={orderDetails.base_price ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cost_pickup_extended_meters"
                        label="تكلفة المتر للمسافة الاضافية قبل نقطة البداية"
                        value={orderDetails.cost_pickup_extended_meters ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cost_dropoff_extended_meters"
                        label="تكلفة المتر للمسافة الاضافية بعد نقطة النهاية"
                        value={orderDetails.cost_dropoff_extended_meters ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="extended_meters"
                        label="تكلفة الامتار الاضافية"
                        value={orderDetails.extended_meters ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="hourly_rate"
                        label="معدل تكلفة الساعة"
                        value={orderDetails.hourly_rate ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="hours_total"
                        label="تكلفة اجمالي الساعات"
                        value={orderDetails.hours_total ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cost_luxury_percent"
                        label="نسبة الزيادة لطلب سيارة فارهة"
                        value={orderDetails.cost_luxury_percent ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cost_penalty"
                        label="تكلفة الغرامة"
                        value={orderDetails.cost_penalty ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="اقل مسافة لتحديد وصول السائق"
                        label="min_arrive_distance"
                        value={orderDetails.min_arrive_distance ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="اقل مسافة عن نقطة الوصول لانهاء الرحلة"
                        label="min_dropoff_distance"
                        value={orderDetails.min_dropoff_distance ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="cost_meter_rate"
                        label="معدل تكلفة المتر"
                        value={orderDetails.cost_meter_rate ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        name="total_cost"
                        label="اجمالي التكلفة"
                        value={orderDetails.total_cost ?? 0}
                        InputProps={{ readOnly: true }}
                    />
                </Box>
            </Stack>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
                width="100%"
            >
                <Box flex="1" minWidth="280px">
                    {/* <Typography variant="subttitle2" marginBottom="10px">
                        مسار السائق
                    </Typography>{" "} */}
                    {orderDetails.status == "completed" ? (
                        <TripPath
                            path={orderDetails.path.coordinates}
                            driverPath={
                                orderDetails?.driver_path?.coordinates ?? []
                            }
                            pickupExtendedPath={
                                orderDetails?.pickup_extended_path
                                    ?.coordinates ?? []
                            }
                            extendedPath={
                                orderDetails?.extended_path?.coordinates ?? []
                            }
                        />
                    ) : (
                        <p>رحلة غير مكتملة</p>
                    )}
                </Box>
            </Box>
        </Stack>
    );
};

export default OrderDetails;
