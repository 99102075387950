import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Typography } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    LinkButton,
    SearchBox,
    SortBox,
    DisableStatusFilterBox,
    LabelCard,
    CustomPagination,
} from "../../../components";
// icon
import { MdAdd } from "react-icons/md";

const Cities = () => {
    const userToken = useSelector((state) => state.userToken);
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(40);
    const [sortValue, setSortValue] = useState("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [filterValues, setFilterValues] = useState({
        status: "is_disabled:eq:false",
    });
    const [dataChanged, setDataChanged] = useState(false);
    // ------------------------------------------- get_all_areas ----------------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/cities?page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}&filters=${filterValues.status}`
            )
            .then((response) => {
                setCities(response.data.data);
                setTotalItems(response.data.meta.total);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    }, [page, itemsPerPage, searchValue, sortValue, filterValues, dataChanged]);

    return (
        <Stack gap="20px">
            <PageTitle title="إدارة المدن" />
            <Stack gap="20px">
                <LinkButton text="إضافة" icon={<MdAdd />} to="/add-city" />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <DisableStatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                {loading ? (
                    <RingsLoader />
                ) : cities.length === 0 && searchValue === "" ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : cities.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1">
                        {" "}
                        لا يوجد عناصر لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    cities.map((city) => (
                        <LabelCard
                            label={city.name}
                            key={city.id}
                            apiPath={`/admin/cities/${city.id}`}
                            editPagePath={`/edit-city/${city.id}`}
                            setDataChanged={setDataChanged}
                        />
                    ))
                )}
            </Box>
            {loading ? (
                <></>
            ) : (
                <CustomPagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            )}
        </Stack>
    );
};

export default Cities;
