import React, { useState, useEffect } from "react";
// react router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// formik
import { useFormik } from "formik";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, TextField, FormControlLabel, Switch } from "@mui/material";
// components
import { PageTitle, SubmitButton, RingsLoader } from "../../../components";
import { ErrorsStack, UploadImagePreview } from "../../../sections";
// form validation schema
import { editCategorySchema } from "../../../schemes/categories/editCategorySchema";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

const EditSosCategory = () => {
    const { categoryId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [defaultImg, setDefaultImg] = useState("");
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        const formData = new FormData();
        for (let i in values) {
            formData.append(i, values[i]);
        }
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/categories/${categoryId}`, formData)
            .then((res) => successAlert("تم تعديل الفئة بنجاح"))
            .catch((error) => errorAlert(error))
            .finally(() => setSubmitting(false));
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            img: null,
            name: "",
            is_disabled: false,
        },
        validationSchema: editCategorySchema,
        onSubmit,
    });
    // ---------- get_current_city_data ----------
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/categories/${categoryId}`)
            .then((res) => {
                setValues((prev) => ({
                    ...prev,
                    // img: res.data.img,
                    name: res.data.name,
                    is_disabled: res.data.is_disabled,
                }));
                setDefaultImg(res.data.img);
                setLoading(false);
            });
    }, []);
    if (loading) <RingsLoader />;
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="تعديل فئة طلبات المساعدة" />
            <Stack
                spacing={1}
                component="form"
                width="100%"
                maxWidth="700px"
                onSubmit={handleSubmit}
            >
                {/* <UploadImagePreview
                    file={file}
                    setFile={setFile}
                    setValues={setValues}
                    defaultImage={defaultImg}
                /> */}
                <TextField
                    name="name"
                    label="اسم الفئة"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={!values.is_disabled}
                            onChange={(e) =>
                                setValues((prev) => ({
                                    ...prev,
                                    is_disabled: !e.target.checked,
                                }))
                            }
                            name="is_disabled"
                        />
                    }
                    label="تفعيل"
                />
                <SubmitButton text="تعديل" disabled={isSubmitting} />
                <ErrorsStack errors={errors} touched={touched} />
            </Stack>
        </Stack>
    );
};

export default EditSosCategory;
