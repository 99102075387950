import React, { useState, useEffect, useRef, useCallback } from "react";
// google maps
import {
    GoogleMap,
    DrawingManager,
    useLoadScript,
} from "@react-google-maps/api";

const DrawOnMap = ({ values, setValues, resetDrawOnMap }) => {
    const polygonRef = useRef();
    const listenersRef = useRef([]);
    const [polygonCoords, setPolygonCoords] = useState([]);
    const [center, setCenter] = useState({
        lat: 32.1145535,
        lng: 20.0641798,
    });
    const { isLoaded, error } = useLoadScript({
        libraries: ["drawing"],
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    }); // script loader for map
    // when polygon completed get the valeus
    const onPolygonComplete = (polygon) => {
        polygonRef.current = polygon;
        const path = polygon.getPath();
        listenersRef.current.push(
            path.addListener("set_at", onEdit),
            path.addListener("insert_at", onEdit),
            path.addListener("remove_at", onEdit)
        );
        const coords = polygon
            .getPath()
            .getArray()
            .map((coord) => ({
                lat: coord.lat(),
                lng: coord.lng(),
            }));
        setPolygonCoords(coords);
        convertCoordsLatLngObjToArr(setValues, coords);
    };
    // reset the draw
    useEffect(() => {
        if (resetDrawOnMap) {
            polygonRef.current.setMap(null);
            setValues((prev) => ({
                ...prev,
                geom: { type: "Polygon", coordinates: [] },
            }));
        }
    }, [resetDrawOnMap]);

    const removeLastPoint = () => {
        if (polygonCoords.length > 0) {
            // remove last point from state
            setPolygonCoords((prev) => prev.slice(0, prev.length - 1));
            const newCoords = polygonCoords.slice(0, polygonCoords.length - 1);
            // assing new points to the polygon we drawed
            polygonRef.current.setPath(newCoords);
            // convert the values from latlng to fit the values in database
            convertCoordsLatLngObjToArr(setValues, newCoords);
        }
    };
    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map((latLng) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPolygonCoords(nextPath);
            let valuesCoordinatesValue = [];
            nextPath.forEach((el) => {
                const smArr = [el.lng, el.lat];
                valuesCoordinatesValue.push(smArr);
            });
            setValues((prev) => ({
                ...prev,
                geom: {
                    type: "Polygon",
                    coordinates: [valuesCoordinatesValue],
                },
            }));
        }
    }, [setPolygonCoords]);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            zoom={8}
            libraries={["drawing"]}
            center={center ?? { lat: 32.1145535, lng: 20.0641798 }}
            // onRightClick={removeLastPoint}
            onEdit={onEdit}
        >
            <DrawingManager
                onPolygonComplete={onPolygonComplete}
                drawingMode="polygon"
                options={{
                    drawingControl: true,
                    drawingControlOptions: {
                        // position: window?.google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            // window.google.maps.drawing.OverlayType.POLYGON,
                        ],
                    },
                    polygonOptions: {
                        fillColor: "#000",
                        fillOpacity: 0.5,
                        strokeWeight: 2,
                        clickable: false,
                        editable: true,
                        zIndex: 1,
                    },
                }}
            />
        </GoogleMap>
    ) : (
        <></>
    );
};

export default DrawOnMap;
const convertCoordsArrToLatLngObj = (coords) => {
    let result = [];
    if (coords.length) {
        coords[0].map((point) => {
            const obj = { lat: point[1], lng: point[0] };
            result.push(obj);
        });
    }
    return result;
};

const convertCoordsLatLngObjToArr = (setValues, coords) => {
    let result = [];
    coords.forEach((el) => {
        const smArr = [el.lng, el.lat];
        result.push(smArr);
    });
    setValues((prev) => ({
        ...prev,
        geom: {
            type: "Polygon",
            coordinates: [result],
        },
    }));
    // return result;
};
