import React, { useState, useEffect, useRef } from "react";
// mui
import { Box, Avatar, Button } from "@mui/material";
// icons
import { FiUploadCloud } from "react-icons/fi";

const UploadImagePreview = ({
    defaultImage,
    file,
    setFile,
    setValues,
    IPWidth = "200px",
    IPHeight = "200px",
    IPVariant = "rounded",
    IPObjectFit = "cover",
    resetPreview = false,
    withoutUploadBtn = false,
}) => {
    const [imgSrc, setImgSrc] = useState("");
    const inputFileRef = useRef();
    // --------------------------------------- handle file change to pass file to preview component ------------------------------
    const handleFileChange = (e) => {
        let x = e.target.files[0];
        setFile(x);
        setValues((prevValues) => ({ ...prevValues, img: x }));
    };
    // --------------------------------------- show file in preview ---------------------------------------
    const showFileInPreview = (file) => {
        if (typeof file !== "string") {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImgSrc(reader.result);
            };
        }
    };
    // when file chage
    useEffect(() => {
        if (file) {
            showFileInPreview(file);
        }
    }, [file]);
    // reset preview
    useEffect(() => {
        if (resetPreview) {
            setImgSrc("");
        }
    }, [resetPreview]);
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
        >
            <Avatar
                src={imgSrc === "" ? defaultImage : imgSrc}
                alt="uploaded Image"
                variant={IPVariant}
                sx={{
                    width: `${IPWidth}`,
                    height: `${IPHeight}`,
                    "& .MuiAvatar-img": {
                        objectFit: IPObjectFit,
                    },
                }}
            />
            {/* upload button  */}
            {/* default button we hidden  */}
            <input
                type="file"
                onChange={handleFileChange}
                name="img"
                ref={inputFileRef}
                hidden
                accept="image/png, image/gif, image/jpeg"
            />
            {/* custom file explore we create  */}
            {!withoutUploadBtn && (
                <Box display="flex" justifyContent="center" width="100%">
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ color: "basicColor.white" }}
                        endIcon={<FiUploadCloud />}
                        onClick={() => inputFileRef.current.click()}
                    >
                        تحميل صورة
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default UploadImagePreview;
