import * as yup from "yup";

// login validation
export const pushNotificationSchema = yup.object().shape({
  title: yup
    .string()
    .min(8, "عنوان الاشعار قصير جدا يجب ان يكون عنوان الاشعار اكثر من 8 حروف")
    .required("اخل عنوان الاشعار"),
  body: yup
    .string()
    .min(25, "موضوع الاشعار قصير جدا يجب ان يكون عنوان الاشعار اكثر من 25 حرف")
    .required("ادخل موضوع الاشعار"),
});
