import React from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// formik
import { useFormik } from "formik";
// mui
import {
    Stack,
    TextField,
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";
// components
import { LinkButton, PageTitle, SubmitButton } from "../../../components";
import { ErrorsStack } from "../../../sections";
// schema
import { pushNotificationSchema } from "../../../schemes/notification/pushNotificationSchema";
//alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

// ----------------------------------- page_function ----------------------------
const Notification = () => {
    const userToken = useSelector((state) => state.userToken);
    // ---------- submit_form -----------
    const onSubmit = (values) => {
        const formData = new FormData();
        for (let i in values) formData.append(i, values[i]);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/fcm-notifications`, formData)
            .then((res) => {
                setSubmitting(false);
                successAlert("تم ارسال الاشعار الى جميع المستخدمين");
                resetForm();
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configraion -----------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        resetForm,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            topic: "all",
            title: "",
            body: "",
        },
        validationSchema: pushNotificationSchema,
        onSubmit,
    });
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="ارسال اشعار" />
            <Stack
                width="100%"
                maxWidth="700px"
                gap="20px"
                component="form"
                onSubmit={handleSubmit}
            >
                <Box display="flex" justifyContent="flex-start" width="100%">
                    <LinkButton
                        text="الاشعارات المرسلة"
                        to="/view-all-notification"
                    />
                </Box>
                <TextField
                    name="title"
                    value={values.title}
                    label="العنوان"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    name="body"
                    value={values.body}
                    label="المحتوى"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <FormControl fullWidth>
                    <InputLabel>ارسال الى</InputLabel>
                    <Select
                        value={values.topic}
                        label="ارسال الى"
                        onChange={(e) =>
                            setValues((prev) => ({
                                ...prev,
                                topic: e.target.value,
                            }))
                        }
                    >
                        <MenuItem value="all">جميع المستخدمين</MenuItem>
                        <MenuItem value="customer"> العملاء</MenuItem>
                        <MenuItem value="driver">السائقين</MenuItem>
                        <MenuItem value="test">المطورين</MenuItem>
                    </Select>
                </FormControl>
                <SubmitButton text="ارسال الاشعار" disabled={isSubmitting} />
                <ErrorsStack errors={errors} touched={touched} />
            </Stack>
        </Stack>
    );
};

export default Notification;
