import React from "react";
// redux
import { useSelector } from "react-redux";
// formik
import { useFormik } from "formik";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import {
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Switch,
} from "@mui/material";
// components
import { PageTitle, SubmitButton } from "../../../components";
import { ErrorsStack } from "../../../sections";
// form validation schema
import { addCitySchema } from "../../../schemes/cities/addCitySchema";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

function AddCity() {
    const userToken = useSelector((state) => state.userToken);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        const formData = new FormData();
        for (let i in values) {
            formData.append(i, values[i]);
        }
        setSubmitting(false);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/cities`, formData)
            .then((res) => {
                successAlert("تم اضافة المدينة بنجاح");
                resetForm();
            })
            .catch((error) => errorAlert(error))
            .finally(() => setSubmitting(false));
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            name: "",
            is_disabled: true,
        },
        validationSchema: addCitySchema,
        onSubmit,
    });
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="إضافة مدينة" />
            <Stack
                spacing={1}
                component="form"
                width="100%"
                maxWidth="700px"
                onSubmit={handleSubmit}
            >
                <TextField
                    name="name"
                    label="اسم المدينة"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={!values.is_disabled}
                            onChange={(e) =>
                                setValues((prev) => ({
                                    ...prev,
                                    is_disabled: !e.target.checked,
                                }))
                            }
                            name="is_disabled"
                        />
                    }
                    label="تفعيل"
                />
                <SubmitButton text="اضافة" disabled={isSubmitting} />
                <ErrorsStack errors={errors} touched={touched} />
            </Stack>
        </Stack>
    );
}

export default AddCity;
