import React, { useState, useEffect } from "react";
// mui
import { Stack } from "@mui/material";
// components
import ErrorMessage from "./ErrorMessage";

const ErrorsStack = ({ errors, touched }) => {
  return (
    <>
      {errors && (
        <Stack width="100%">
          {Object.keys(errors).map((item, index) => {
            return (
              typeof errors[item] == "string" &&
              touched[item] && (
                <ErrorMessage key={index} errorMsg={errors[item]} />
              )
            );
          })}
          {Object.keys(errors).map((item, index) => {
            return (
              typeof errors[item] != "string" &&
              Object.keys(errors[item]).map((el, index) => (
                <ErrorMessage key={index} errorMsg={errors[item][el]} />
              ))
            );
          })}
        </Stack>
      )}
    </>
  );
};

export default ErrorsStack;
