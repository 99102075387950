import React, { useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import {
    Stack,
    TextField,
    Typography,
    Button,
    FormControlLabel,
    Switch,
} from "@mui/material";
// formik
import { useFormik } from "formik";
// components
import {
    PageTitle,
    DrawOnMap,
    CitySelect,
    SubmitButton,
} from "../../../components";
import { ErrorsStack } from "../../../sections";
// validaiton schema
import { addAreaSchema } from "../../../schemes/areas/addAreaSchema";
import { errorAlert, successAlert } from "../../../utils/alerts";
import DrawingOnMap from "../../../components/map/DrawingOnMap";

const AddArea = () => {
    const userToken = useSelector((state) => state.userToken);
    const [resetCity, setResetCity] = useState(false);
    const [resetDrawOnMap, setResetDrawOnMap] = useState(false);
    const [showDrawingErrorMessage, setShowDrawingErrorMessage] =
        useState(false);
    // ---------------------------- Submit_Form ------------------------------------
    const onSubmit = (values) => {
        if (values.geom.coordinates[0]?.length >= 3) {
            EnhancedAxios(null, userToken)
                .post(`/admin/areas`, values)
                .then((res) => {
                    successAlert("تم اضافة المنطقة بنجاح");
                    setSubmitting(false);
                    resetForm();
                    setResetDrawOnMap(true);
                    setResetCity(true);
                })
                .catch((error) => {
                    errorAlert(error);
                    setSubmitting(false);
                });
        } else {
            setShowDrawingErrorMessage(true);
            setSubmitting(false);
            return;
        }
    };
    // ---------------------------- form configration ----------------------------
    const {
        values,
        setValues,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues: {
            name: "",
            delivery_price: "",
            city: {
                id: "",
            },
            geom: {
                type: "Polygon",
                coordinates: [],
            },
            is_disabled: true,
        },
        validationSchema: addAreaSchema,
        onSubmit,
    });
    return (
        <Stack gap="40px" alignItems="center">
            <PageTitle title="اضافة المنطقة" />
            <Stack
                component="form"
                width="100%"
                maxWidth="700px"
                gap="10px"
                onSubmit={handleSubmit}
            >
                <TextField
                    name="name"
                    label="اسم المنطقة"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSubmit={handleSubmit}
                />
                <TextField
                    type="number"
                    name="delivery_price"
                    label="التكلفة"
                    value={values.delivery_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <CitySelect
                    setValues={setValues}
                    values={values}
                    resetCity={resetCity}
                    setResetCity={setResetCity}
                />
                <DrawingOnMap
                    values={values}
                    setValues={setValues}
                    resetDrawOnMap={resetDrawOnMap}
                    setResetDrawOnMap={setResetDrawOnMap}
                />
                <Stack width="130px">
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setResetDrawOnMap(true)}
                    >
                        ازالة الرسمة
                    </Button>
                </Stack>
                <FormControlLabel
                    control={
                        <Switch
                            checked={!values.is_disabled}
                            onChange={(e) =>
                                setValues((prev) => ({
                                    ...prev,
                                    is_disabled: !e.target.checked,
                                }))
                            }
                            name="is_disabled"
                        />
                    }
                    label="تفعيل"
                />
                <SubmitButton text="إنشاء المنطقة" disabled={isSubmitting} />
                <Stack>
                    <ErrorsStack errors={errors} touched={touched} />
                    {showDrawingErrorMessage ? (
                        <Typography variant="body2" color="error">
                            - ارسم المنطقة المراد اضافتها على الخريطة
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddArea;
