import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// router
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { Box, Stack, Divider, Rating, Typography, Button } from "@mui/material";
import { RingsLoader } from "../../components";
// sections
import { DriverTripsDetails } from "../../sections";
import { warningAlert } from "../../utils/alerts";

const DriverInfo = ({ driver, setRerender }) => {
    // const [openDialog, setOpenDialog] = useState(false);
    // const handleCloseDialog = () => setOpenDialog(false);
    // const handleOpenDialog = () => {
    //     if (!driver?.driver_trx?.id)
    //         warningAlert("هذا السائق ليس لديه اى رحلات سابقة");
    //     else setOpenDialog(true);
    // };
    return (
        <Stack>
            {/* rating row  */}
            <Stack
                direction="row"
                justifyContent="space-between"
                padding="20px 0"
            >
                <Typography variant="subtitle2">التقييم</Typography>
                <Rating
                    name="read-only"
                    value={driver.avg_rate_as_driver ?? 0}
                    precision={0.5}
                    readOnly
                />
            </Stack>
            <Divider flexItem />
            {/* row of credit info  */}
            <Stack
                direction="row"
                gap="16px"
                flexWrap="wrap"
                divider={<Divider flexItem orientation="vertical" />}
                textAlign="center"
                // component={Button}
                // onClick={handleOpenDialog}
            >
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                    py={2}
                >
                    <Typography variant="subtitle2">
                        اجمالي قيمة المبلغ
                    </Typography>
                    <Typography variant="h6">
                        {driver?.statistic?.total_cost || 0}
                    </Typography>
                </Stack>
                {/* <Divider flexItem orientation="vertical" /> */}
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                    py={2}
                >
                    <Typography variant="subtitle2">
                        اجمالي قيمة ارباح التطبيق 
                    </Typography>
                    <Typography variant="h6">
                        {driver?.statistic?.total_cost_app_profit || 0}
                    </Typography>
                </Stack>
                {/* <Divider flexItem orientation="vertical" /> */}
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                    py={2}
                >
                    <Typography variant="subtitle2" textAlign={"center"}>
                        اجمالي قيمة ارباح السائق
                    </Typography>
                    <Typography variant="h6">
                        {driver?.statistic?.total_cost_driver_profit || 0}
                    </Typography>
                </Stack>
                {/* <Divider flexItem orientation="vertical" /> */}
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                    py={2}
                >
                    <Typography variant="subtitle2" textAlign={"center"}>
                        اجمالي عدد الرحلات
                    </Typography>
                    <Typography variant="h6">
                        {driver?.statistic?.total_trips || 0}
                    </Typography>
                </Stack>
                <Stack
                    alignItems="center"
                    gap="10px"
                    flex="1"
                    width="200px"
                    justifyContent="space-between"
                    minHeight="84px"
                    py={2}
                >
                    <Typography variant="subtitle2" textAlign={"center"}>
                        اجمالي قيمة رصيد السائق
                    </Typography>
                    <Typography variant="h6">
                        {driver?.driver_wallet?.balance || 0}
                    </Typography>
                </Stack>
            </Stack>
            {/* dialog of trips */}
            {/* {driver?.driver_trx?.id ? (
                <DriverTripsDetails
                    open={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    driverTrxId={driver?.driver_trx?.id}
                    setRerender={setRerender}
                />
            ) : (
                <></>
            )} */}
        </Stack>
    );
};

export default DriverInfo;
