import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Pagination, Typography } from "@mui/material";
// icon
import { MdAdd } from "react-icons/md";
// components
import {
    LabelCard,
    LinkButton,
    PageTitle,
    RingsLoader,
    SortBox,
    SearchBox,
    DisableStatusFilterBox,
    CustomPagination,
    CityFilter,
} from "../../../components";
// alerts
import { errorAlert } from "../../../utils/alerts";
const SosCategories = () => {
    const userToken = useSelector((state) => state.userToken);
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        status: "is_disabled:eq:false",
    });
    const [dataChanged, setDataChanged] = useState(false);
    // ------------------------------------------- get_all_areas ----------------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/categories?page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}&filters=parent_id:${process.env.React_APP_PARENT_ID_SOS_CATEGORIES},${filterValues.status},${filterValues.city_id}`
            )
            .then((response) => {
                setCategories(response.data.data);
                setTotalItems(response.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, itemsPerPage, searchValue, sortValue, filterValues, dataChanged]);
    return (
        <Stack gap="20px">
            <PageTitle title="إدارة فئات طلبات المساعدة" />
            <Stack gap="20px">
                <LinkButton
                    text="اضافة"
                    to="/add-sos-category"
                    icon={<MdAdd />}
                />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <DisableStatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            {loading ? (
                <RingsLoader />
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    {categories.length ? (
                        categories.map((category) => (
                            <LabelCard
                                key={category.id}
                                label={category.name}
                                id={category.id}
                                apiPath={`/admin/categories/${category.id}`}
                                editPagePath={`/edit-sos-category/${category.id}`}
                                setDataChanged={setDataChanged}
                            />
                        ))
                    ) : (
                        <Typography variant="body1">
                            لا يوجد فئات لعرضها.
                        </Typography>
                    )}
                </Box>
            )}
            {loading ? (
                <></>
            ) : (
                <CustomPagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            )}
        </Stack>
    );
};

export default SosCategories;
