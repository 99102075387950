import React from "react";
import { Stack } from "@mui/material";

import { Rings } from "react-loader-spinner";
const RingsLoader = ({ height }) => {
  return (
    <Rings
      height="80"
      width="80"
      color="rgb(120, 83, 160)"
      radius="6"
      wrapperStyle={{ height: height, justifyContent: "center" }}
      wrapperClass=""
      visible={true}
      ariaLabel="rings-loading"
    />
  );
};

export default RingsLoader;
