import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// router
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, TextField, Avatar } from "@mui/material";
// componenst
import { RingsLoader, PageTitle } from "../../../components";
import { getUserRole } from "../../../utils/roles/getUserRole";

const DriverRequest = () => {
    const { requestId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    const [driver, setDriver] = useState({});
    const [loading, setLoading] = useState(true);
    const role = getUserRole(userData);

    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/${role}/driver-forms/${requestId}`)
            .then((res) => {
                setDriver(res.data);
                setLoading(false);
            });
    }, []);
    // ---------- page_ui ----------
    if (loading) return <RingsLoader />;
    return (
        <Stack width="100%" alignItems="center">
            <Stack gap="20px" width="100%" maxWidth="700px">
                <PageTitle title="تفاصيل طلب انضمام سائق" />
                <Stack gap="10px">
                    <Avatar
                        src={driver.img}
                        alt={`${driver.name} image`}
                        variant="rounded"
                        loading="lazy"
                        sx={{ width: "150px", height: "150px", margin: "auto" }}
                    />
                    <TextField
                        type="text"
                        name="name"
                        label="الاسم"
                        value={driver.name}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="phone"
                        label="الهاتف"
                        value={
                            driver.phone == null ? "غير موجود" : driver.phone
                        }
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="city"
                        label="المدينة"
                        value={driver.city?.name}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="gender"
                        label="النوع"
                        value={driver.gender}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="nationality"
                        label="الجنسية"
                        value={driver.nationality}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="has_second_job"
                        label="لديه وظيفة اخرى"
                        value={driver.has_second_job ? "نعم" : "لا"}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="preferred_shift"
                        label="اوقات العمل المفضلة"
                        value={driver.preferred_shift}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="has_private_car"
                        label="لديه سيارة خاصة"
                        value={driver.has_private_car ? "نعم" : "لا"}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="car_type"
                        label="نوع السيارة"
                        value={driver.car_type}
                        inputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        type="text"
                        name="car_model"
                        label="موديل السيارة"
                        value={driver.car_model}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="car_year"
                        label="سنة انتاج السيارة السيارة"
                        value={driver.car_year}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default DriverRequest;
