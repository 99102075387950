import React, { useState } from "react";
// router
import { useParams } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// formik
import { useFormik } from "formik";
// mui
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Stack,
    Typography,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
// componetns
import {
    SubmitButton,
    PaymentMethodSelect,
    TransactionTypeSelect,
} from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts";

const UserChargeCash = ({
    openChargeCashForm,
    setOpenChargeCashForm,
    currentBalance,
    setNewUserWalletData,
    newUserWalletData,
}) => {
    const { userId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    // ---------- charge_cash_form_toggelers_functions ----------
    const handleOpenDialog = () => {
        setOpenChargeCashForm(true);
    };
    const handleCloseDialog = () => {
        setOpenChargeCashForm(false);
    };

    // ---------- submit_form ----------
    const onSubmit = (values) => {
        EnhancedAxios(null, userToken)
            .post(`/admin/wallet-transactions`, values)
            .then((res) => {
                setNewUserWalletData((prev) => ({
                    ...prev,
                    newBalanceValue:
                        res.data.type == "deposit"
                            ? parseInt(newUserWalletData.newBalanceValue) +
                              parseInt(values.amount)
                            : parseInt(newUserWalletData.newBalanceValue) -
                              parseInt(values.amount),
                    newTotalTransactionCount:
                        newUserWalletData.newTotalTransactionCount + 1,
                    newOverallBalance:
                        res.data.type == "deposit"
                            ? newUserWalletData.newOverallBalance +
                              values.amount
                            : newUserWalletData.newOverallBalance - 0,
                }));
                successAlert(`تم تغيير رصيد المستخدم`);
                setSubmitting(false);
                setOpenChargeCashForm(false);
                resetForm();
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            type: "",
            amount: 0,
            payment_method: "",
            user: {
                id: userId,
            },
            notes: "",
            is_confirmed: true,
        },
        onSubmit,
    });
    // ---------- page_ui ----------
    return (
        <Dialog
            open={openChargeCashForm}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>عملية شحن رصيد </DialogTitle>
            <DialogContent>
                <Stack component="form" onSubmit={handleSubmit} gap="20px">
                    <Typography variant="body2">
                        رصيد المستخدم الحالى :{" "}
                        {newUserWalletData.newBalanceValue ?? currentBalance}
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="20px"
                        flexWrap="wrap"
                        py={1}
                    >
                        <TextField
                            autoFocus
                            fullWidth
                            name="amount"
                            label="قيمة المبلغ"
                            value={values.amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            inputProps={{
                                min: 0,
                            }}
                            required
                        />
                        <PaymentMethodSelect
                            values={values}
                            setValues={setValues}
                        />
                        <TransactionTypeSelect
                            values={values}
                            setValues={setValues}
                        />
                        <TextField
                            fullWidth
                            name="notes"
                            label="ملاحظات"
                            value={values.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Box>
                    <SubmitButton text="تأكيد" disabled={isSubmitting} />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default UserChargeCash;
