import React, { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import {
    Stack,
    TextField,
    Box,
    Typography,
    InputAdornment,
    Button,
    FormControlLabel,
    Switch,
} from "@mui/material";
// formik
import { useFormik } from "formik";
// components
import {
    PageTitle,
    CitySelect,
    SubmitButton,
    RingsLoader,
    AnotherMap,
    DrawOnMap,
} from "../../../components";
import { ErrorsStack } from "../../../sections";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";
// schema
import { editAreaSchema } from "../../../schemes/areas/editAreaSchema";

const EditArea = () => {
    const { areaId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(true);
    const [showDrawingErrorMessage, setShowDrawingErrorMessage] =
        useState(false);
    const [showDrawingOnMap, setShowDrawingOnMap] = useState(false);
    // ---------------------------- Submit_Form ------------------------------------
    const onSubmit = (values) => {
        if (values.geom.coordinates.length) {
            EnhancedAxios(null, userToken)
                .put(`/admin/areas/${areaId}`, values)
                .then((res) => successAlert("تم تعديل المنطقة "))
                .catch((error) => errorAlert(error))
                .finally(() => setSubmitting(false));
        } else {
            setShowDrawingErrorMessage(true);
            setSubmitting(false);
            return;
        }
    };
    // ---------------------------- form_configration ----------------------------
    const {
        values,
        setValues,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useFormik({
        initialValues: {
            name: "",
            delivery_price: 0,
            city_id: "",
            geom: {
                type: "Polygon",
                coordinates: [],
            },
            is_disabled: true,
        },
        validationSchema: editAreaSchema,
        onSubmit,
    });
    // ---------------------------- get_area_data ------------------------------------
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/areas/${areaId}`)
            .then((response) => {
                setValues((prev) => ({
                    ...prev,
                    name: response.data.name,
                    delivery_price: response.data.delivery_price,
                    city_id: response.data.city.id,
                    geom: {
                        type: "Polygon",
                        coordinates: response.data.geom?.coordinates ?? [],
                    },
                    is_disabled: response.data.is_disabled,
                }));
                if (
                    response.data.geom === null ||
                    response.data.geom.coordinates.length === 0
                ) {
                    setShowDrawingOnMap(true);
                }
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [areaId]);
    const [center, setCenter] = useState({});
    // ---------------------------- Render_loading_rings_till_data_come ----------------------------
    if (loading) return <RingsLoader />;
    return (
        <Stack gap="40px" alignItems="center">
            <PageTitle title="تعديل المنطقة" />
            <Stack
                component="form"
                width="100%"
                maxWidth="700px"
                gap="10px"
                onSubmit={handleSubmit}
            >
                <TextField
                    name="name"
                    label="اسم المنطقة"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    name="delivery_price"
                    type="number"
                    label="التكلفة"
                    value={values.delivery_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                د.ل
                            </InputAdornment>
                        ),
                    }}
                />
                <CitySelect setValues={setValues} values={values} />
                <Box sx={{ height: "400px" }}>
                    {showDrawingOnMap ? (
                        <DrawOnMap values={values} setValues={setValues} />
                    ) : (
                        <AnotherMap values={values} setValues={setValues} />
                    )}
                </Box>
                <FormControlLabel
                    control={
                        <Switch
                            checked={!values.is_disabled}
                            onChange={(e) =>
                                setValues((prev) => ({
                                    ...prev,
                                    is_disabled: !e.target.checked,
                                }))
                            }
                            name="is_disabled"
                        />
                    }
                    label="تفعيل"
                />
                <SubmitButton text="تعديل" disabled={isSubmitting} />
                <Stack>
                    <ErrorsStack errors={errors} touched={touched} />
                    {showDrawingErrorMessage ? (
                        <Typography variant="body2" color="error">
                            - ارسم المنطقة المراد اضافتها على الخريطة
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditArea;
