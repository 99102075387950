import React, { useState } from "react";
import {
    GoogleMap,
    Polyline,
    Marker,
    useLoadScript,
} from "@react-google-maps/api";
import { useEffect } from "react";
import { Stack, Button, Typography } from "@mui/material";

const mapContainerStyle = {
    width: "100%",
    height: "400px",
};
const pathesLabels = [
    {
        label: "مسار الرحلة",
        color: "#5e2e90",
    },
    {
        label: "مسار السائق الي الزبون",
        color: "#19199b",
    },
    {
        label: "مسار اضافي الي الزبون",
        color: "#2E8F83",
    },
    {
        label: "مسار رحلة اضافي",
        color: "#8F4C2E",
    },
];
const TripPath = ({ path, driverPath, pickupExtendedPath, extendedPath }) => {
    const [pathArrObj, setPathArrObj] = useState([]);
    const [driverPathArrObj, setDriverPathArrObj] = useState([]);
    const [pickupExtendedPathArrObj, setPickupExtendedPathArrObj] = useState(
        []
    );
    const [extendedPathArrObj, setExtendedPathArrObj] = useState([]);
    const [showPath, setShowPath] = useState(true);
    const [showDriverPath, setShowDriverPath] = useState(false);
    const [showPickupExtendedPath, setShowPickupExtendedPath] = useState(false);
    const [showExtendedPath, setShowExtendedPath] = useState(false);

    const [center, setCenter] = useState(null);

    const { isLoaded, error } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    }); // script loader for map
    useEffect(() => {
        setPathArrObj(convertCoordsArrToLatLngObj(path));
        setDriverPathArrObj(convertCoordsArrToLatLngObj(driverPath));
        setPickupExtendedPathArrObj(
            convertCoordsArrToLatLngObj(pickupExtendedPath)
        );
        setExtendedPathArrObj(convertCoordsArrToLatLngObj(extendedPath));
        setCenter(convertCoordsArrToLatLngObj(path)[0]);
    }, []);

    return pathArrObj.length && isLoaded && center != null ? (
        <Stack gap="16px">
            {/* labels  */}
            <Stack direction="row" gap="16px" flexWrap="wrap">
                {/* driver path  */}
                <Stack
                    direction="row"
                    component={Button}
                    gap="8px"
                    onClick={() => {
                        setShowDriverPath((prev) => !prev);
                        if (
                            showDriverPath === false &&
                            driverPathArrObj.length > 0
                        ) {
                            setCenter(driverPathArrObj[0]);
                        }
                    }}
                >
                    <Typography
                        sx={{
                            width: "32px",
                            height: "32px",
                            backgroundColor: pathesLabels[1].color,
                            borderRadius: "4px",
                        }}
                    ></Typography>
                    <Typography>{pathesLabels[1].label}</Typography>
                </Stack>
                {/* pickup driver path  */}
                <Stack
                    direction="row"
                    component={Button}
                    gap="8px"
                    onClick={() => {
                        setShowPickupExtendedPath((prev) => !prev);
                        if (
                            showDriverPath === false &&
                            pickupExtendedPathArrObj.length > 0
                        ) {
                            setCenter(pickupExtendedPathArrObj[0]);
                        }
                    }}
                >
                    <Typography
                        sx={{
                            width: "32px",
                            height: "32px",
                            backgroundColor: pathesLabels[2].color,
                            borderRadius: "4px",
                        }}
                    ></Typography>
                    <Typography>
                        {pathesLabels[2].label}{" "}
                        <Typography variant="body2" component="span">
                            {pickupExtendedPath.length === 0 ? "(لا يوجد)" : ""}
                        </Typography>
                    </Typography>
                </Stack>
                {/* path  */}
                <Stack
                    direction="row"
                    component={Button}
                    gap="8px"
                    onClick={() => {
                        setShowPath((prev) => !prev);
                        if (showDriverPath === false && pathArrObj.length > 0) {
                            setCenter(pathArrObj[0]);
                        }
                    }}
                >
                    <Typography
                        sx={{
                            width: "32px",
                            height: "32px",
                            backgroundColor: pathesLabels[0].color,
                            borderRadius: "4px",
                        }}
                    ></Typography>
                    <Typography>{pathesLabels[0].label} </Typography>
                </Stack>
                {/* extended path  */}
                <Stack
                    direction="row"
                    component={Button}
                    gap="8px"
                    onClick={() => {
                        setShowExtendedPath((prev) => !prev);
                        if (
                            showDriverPath === false &&
                            extendedPathArrObj.length > 0
                        ) {
                            setCenter(extendedPathArrObj[0]);
                        }
                    }}
                >
                    <Typography
                        sx={{
                            width: "32px",
                            height: "32px",
                            backgroundColor: pathesLabels[3].color,
                            borderRadius: "4px",
                        }}
                    ></Typography>
                    <Typography>
                        {pathesLabels[3].label}{" "}
                        <Typography variant="body2" component="span">
                            {pickupExtendedPath.length === 0 ? "(لا يوجد)" : ""}
                        </Typography>
                    </Typography>
                </Stack>
            </Stack>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={14}
                center={center}
            >
                {showPath && (
                    <>
                        <Polyline
                            path={pathArrObj}
                            options={{
                                strokeColor: pathesLabels[0].color, // Color of the line
                                strokeOpacity: 1,
                                strokeWeight: 4, // Width of the line
                            }}
                        />
                        <Marker
                            position={pathArrObj[0]}
                            label="نقطة الانطلاق"
                        />
                        <Marker
                            position={pathArrObj[pathArrObj.length - 1]}
                            label="نقطة الوصول"
                        />
                    </>
                )}

                {showDriverPath && (
                    <>
                        <Polyline
                            path={driverPathArrObj}
                            options={{
                                strokeColor: pathesLabels[1].color, // Color of the line
                                strokeOpacity: 1,
                                strokeWeight: 4, // Width of the line
                            }}
                        />
                        <Marker
                            position={driverPathArrObj[0]}
                            label="نقطة الانطلاق"
                        />
                        <Marker
                            position={
                                driverPathArrObj[driverPathArrObj.length - 1]
                            }
                            label="نقطة الوصول"
                        />
                    </>
                )}

                {showPickupExtendedPath && (
                    <>
                        <Polyline
                            path={pickupExtendedPathArrObj}
                            options={{
                                strokeColor: pathesLabels[2].color, // Color of the line
                                strokeOpacity: 1,
                                strokeWeight: 4, // Width of the line
                            }}
                        />
                        <Marker
                            position={pickupExtendedPathArrObj[0]}
                            label="نقطة الانطلاق"
                        />
                        <Marker
                            position={
                                pickupExtendedPathArrObj[
                                    pickupExtendedPathArrObj.length - 1
                                ]
                            }
                            label="نقطة الوصول"
                        />
                    </>
                )}

                {showExtendedPath && (
                    <>
                        <Polyline
                            path={extendedPathArrObj}
                            options={{
                                strokeColor: pathesLabels[3].color, // Color of the line
                                strokeOpacity: 1,
                                strokeWeight: 4, // Width of the line
                            }}
                        />
                        <Marker
                            position={extendedPathArrObj[0]}
                            label="نقطة الانطلاق"
                        />
                        <Marker
                            position={
                                extendedPathArrObj[
                                    extendedPathArrObj.length - 1
                                ]
                            }
                            label="نقطة الوصول"
                        />
                    </>
                )}
            </GoogleMap>
        </Stack>
    ) : (
        <></>
    );
};
export default TripPath;

const convertCoordsArrToLatLngObj = (coords) => {
    let result = [];
    if (coords.length) {
        coords.map((point) => {
            const obj = { lat: point[1], lng: point[0] };
            result.push(obj);
        });
    }
    return result;
};
