import React, { useState } from "react";
// mui
import { Stack, Button, Divider } from "@mui/material";
// components
import { PageTitle } from "../../../components";
import {
    SystemOptions,
    SocialLinks,
    AboutApp,
    OtpOptions,
    EditAdminData,
    EarlyAccessToggler,
} from "../../../sections";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

function Settings() {
    const [openEditAdminForm, setOpenEditAdminForm] = useState(false);
    const handleCloseEditAdminForm = () => setOpenEditAdminForm(false);
    const handleOpenEditAdminForm = () => setOpenEditAdminForm(true);
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="الضبط" />
            <Stack gap="20px" width="100%" maxWidth="700px">
                <Stack direction="row">
                    <Button
                        variant="outlined"
                        onClick={handleOpenEditAdminForm}
                    >
                        تعديل بيانات الادراة
                    </Button>
                    <EditAdminData
                        open={openEditAdminForm}
                        handleClose={handleCloseEditAdminForm}
                    />
                </Stack>
                <Divider flexItem />
                <SystemOptions />
                <Divider flexItem />
                <SocialLinks />
                <Divider flexItem />
                <AboutApp />
                {/* <Divider flexItem /> */}
                {/* <EarlyAccessToggler /> */}
            </Stack>
        </Stack>
    );
}

export default Settings;
