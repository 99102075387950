import React from "react";
// mui
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
// default sorting values
const defaultSortingValues = [
  {
    label: "الاسم من أ-ى",
    value: "name",
  },
  {
    label: "الاسم من ى-أ",
    value: "-name",
  },
  {
    label: "تاريخ الانشاء من الاحدث الى الاقدم",
    value: "-created_at",
  },
  {
    label: "تاريخ الانشاء من الاقدم الى الاحدث",
    value: "created_at",
  },
  {
    label: "تاريخ التحديث من الاحدث الى الاقدم",
    value: "-updated_at",
  },
  {
    label: "تاريخ التحديث من الاقدم الى الاحدث",
    value: "updated_at",
  },
];
const SortBox = ({
  sortValue,
  setSortValue,
  sortingOptions = defaultSortingValues,
}) => {
  return (
    <Box sx={{ minWidth: "230px", flex: "1" }}>
      <FormControl fullWidth>
        <InputLabel id="sort-box-label">ترتيب حسب</InputLabel>
        <Select
          labelId="sort-box-label"
          id="sort-box"
          value={sortValue}
          label="ترتيب حسب"
          onChange={(e) => setSortValue(e.target.value)}
        >
          {sortingOptions.map((opt) => (
            <MenuItem value={opt.value} key={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SortBox;
