import * as yup from "yup";
const phoneRegex = new RegExp(/^[+]?[0-9]{8,14}$/);
const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;

export const addDriverSchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            "fileSize",
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : "t")
        )
        .test("fileType", "امتداد الصورة يجب ان يكون jpg , png", (value) =>
            value != null
                ? ["image/jpg", "image/png", "image/jpeg"].includes(value.type)
                : "t"
        ),
    name: yup
        .string()
        .min(4, "يجب ان يكون اسم المستخدم اكثر من 4 حروف")
        .required("من فضلك ادخل اسم المستخدم "),
    phone: yup
        .string()
        .required("ادخل رقم الهاتف خاصة المتسخدم")
        .matches(phoneRegex, "كتابة رقم الهاتف مسبوق بكود الدولة"),
    email: yup.string().email("ادخل البريد الالكترونى الصحيح للسائق"),
    has_second_job: yup.boolean(),
    nationality: yup.string().min(2, "اسم الجنسية قصير ").nullable(),
    car_type: yup.string().nullable(),
    car_year: yup.number().nullable(),
    car_model: yup.string().nullable(),
    is_disabled: yup.boolean(),
    password: yup
        .string()
        .min(8, " الرقم السري قصير جدا")
        .required("ادخل الرقم السري!"),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], "تأكد من تطابق الرقم السري"),
});
