export const englishToArabic = {
    ongoing: "جارية",
    claimed: "انتظار وصول السائق",
    requested: "مطلوبة",
    completed: "مكتملة",
    cancelled: "ملغية",
    extended: "ممتدة",
    arrived: "وصل",
    short: "مشوار",
    long: "رحلة",
    male: "ذكر",
    female: "انثى",
    economy: "اقتصادية",
    luxury: "رفاهية",
    pending: "قيد الانتظار",
    completed: "مكتملة",
    driver_profit_increase: "زيادة نسبة الارباح للسائق",
    wallet_credit: "رصيد فى المحفظة ",
    luxury_cars_discount: "تخفيض على سيارة فارهة",
    deposit: "ايداع",
    withdraw: "سحب",
    customer: "عميل",
    driver: "سائق",
    helper: "داعم طلبات المساعدة",
};
export const translateToArabic = (text) => {
    return englishToArabic[text];
};
