import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// mui
import {
    Stack,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";
import EnhancedAxios from "../../api/EnhancedAxios";
import { errorAlert } from "../../utils/alerts";

const PaymentMethodSelect = ({ values, setValues, isRequired = true }) => {
    const [method, setMethod] = useState(values.payment_method ?? "");
    const [methods, setMethods] = useState([]);
    const [loading, setLoading] = useState(true);
    const userToken = useSelector((state) => state.userToken);

    const handleChange = (e) => {
        setMethod(e.target.value);
        setValues((prev) => ({ ...prev, payment_method: e.target.value }));
    };
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/payment-methods?sorts=-name&page=1&paginate=999`)
            .then((res) => {
                setMethods(res.data.data);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, []);
    return (
        <FormControl fullWidth>
            <InputLabel>طريقة الدفع</InputLabel>
            <Select
                value={method}
                label="طريقة الدفع"
                onChange={handleChange}
                required={isRequired}
            >
                {loading ? (
                    <MenuItem value="" disabled>
                        تحميل...
                    </MenuItem>
                ) : (
                    methods.map((method, index) => (
                        <MenuItem key={index} value={method.name}>
                            {method.name}
                        </MenuItem>
                    ))
                )}
            </Select>
        </FormControl>
    );
};

export default PaymentMethodSelect;
