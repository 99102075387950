import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks 
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Typography } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    SelectDate,
    OrderStatusFilter,
    PrintReport,
    SortBox,
    SearchBox,
    CategoriesFilterBox,
} from "../../../components";
import { TableGrid } from "../../../sections";
// cols
import { helpRequestsCols } from "../../../utils/gridCols/helpRequestsCols";
// alerts
import { errorAlert } from "../../../utils/alerts";

// -------------------------------------------------- page_function --------------------------------------
const HelpRequests = () => {
    const userToken = useSelector((state) => state.userToken);
    const {page, setPage} = usePageParams();
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(0);
    const [sortValue, setSortValue] = useState("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [filterValues, setFilterValues] = useState({
        category: "",
    });
    // -------------------------------------------------- page_component --------------------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/sos-requests?page=${page}&paginate=${itemsPerPage}&sorts=${sortValue}&filters=${filterValues.category}&q=${searchValue}`
            )
            .then((res) => {
                setRequests(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, itemsPerPage, sortValue, searchValue, filterValues]);
    return (
        <Stack gap="20px">
            <PageTitle title="طلبات المساعدة" />
            <Stack gap="20px">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <CategoriesFilterBox setFilterValues={setFilterValues} />
                </Box>
            </Stack>
            <Stack>
                {loading ? (
                    <RingsLoader />
                ) : requests.length === 0 ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : (
                    <TableGrid
                        rows={requests}
                        cols={helpRequestsCols}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={page}
                        setCurrentPage={setPage}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default HelpRequests;
