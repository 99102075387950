import { useState } from "react";
// mui components
import { Box, Switch } from "@mui/material";
//axios
import EnhancedAxios from "../../api/EnhancedAxios";
// redux hooks
import { useSelector } from "react-redux";
// alerts
import { successAlert, errorAlert } from "../../utils/alerts";

const VerifySwitch = ({ id, is_verified }) => {
    const [value, setValue] = useState(is_verified);
    const userToken = useSelector((state) => state.userToken);
    const handleChange = (e) => {
        setValue((prev) => !prev);
        let formData = new FormData();
        formData.append("is_verified", e.target.checked);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/users/${id}`, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_verified === true
                        ? "تم التحقق"
                        : "تم التحقق";
                successAlert(alertMsg);
                setValue(response.data.is_verified);
            })
            .catch((error) => {
                errorAlert(error);
                setValue((prev) => prev);
            });
    };
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                pointerEvents: value ? "none" : "auto",
                cursor: value ? "not-allowed" : "pointer",
            }}
        >
            <Switch
                color="success"
                checked={value}
                onChange={handleChange}
                size="small"
            />
        </Box>
    );
};
export default VerifySwitch;
