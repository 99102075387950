import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// form
import { useFormik } from "formik";
// mui
import {
    Stack,
    Box,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Divider,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// schema
import { editOfferSchema } from "../../schemes/offers/editOfferSchema";
// components
import { PageTitle, SubmitButton } from "../../components";
import { ErrorsStack } from "../../sections";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts";
// assets
import { translateToArabic } from "../../utils/translate/englishToArabic";

const OfferForm = ({
    id,
    isDisabled,
    details,
    type,
    tripsGoal,
    amount,
    validDuration,
    expiresAt,
}) => {
    const handleDateChange = (newValue) => {
        setValues((prev) => ({
            ...prev,
            expires_at: dayjs(newValue).format("YYYY-MM-DD"),
        }));
    };
    const userToken = useSelector((state) => state.userToken);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        EnhancedAxios("application/json", userToken)
            .put(`/admin/gift-templates/${id}`, values)
            .then((res) => {
                setSubmitting(false);
                successAlert(`تم تعديل العرض ${translateToArabic(type)}`);
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            is_disabled: isDisabled,
            details: details,
            type: type,
            trips_goal: tripsGoal,
            amount: amount,
            valid_duration: validDuration,
            expires_at: expiresAt,
        },
        validationSchema: editOfferSchema,
        onSubmit,
    });
    return (
        <Stack gap="10px" width="100%">
            <Typography variant="h6">{translateToArabic(type)}</Typography>
            <Stack gap="20px" component="form" onSubmit={handleSubmit}>
                <TextField
                    name="details"
                    label="التفاصيل"
                    value={values.details}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    name="amount"
                    label={
                        type.includes("discount")
                            ? "المبلغ المخفض"
                            : type.includes("driver_profit")
                            ? "نسبة الزيادة"
                            : type.includes("wallet_credit")
                            ? "قيمة الرصيد"
                            : "الجائزة"
                    }
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                        step: ".1",
                    }}
                />
                <TextField
                    type="number"
                    name="trips_goal"
                    label="الهدف / عدد الرحلات"
                    value={values.trips_goal}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Box flex="1" minWidth="230px">
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ width: "100%" }}
                    >
                        <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                                label="تاريخ الانتهاء"
                                onChange={handleDateChange}
                                disablePast
                                sx={{ width: "100%" }}
                                format="DD/MM/YYYY"
                                defaultValue={dayjs(values.expires_at)}
                                required
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>
                {/* <TextField
                    type="number"
                    name="valid_duration"
                    label="مدة الاستخدام / عدد الايام"
                    value={values.valid_duration}
                    onChange={handleChange}
                    onBlur={handleBlur}
                /> */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="تعطيل"
                />
                <SubmitButton text="تاكيد" disabled={isSubmitting} />
                <ErrorsStack errors={errors} touched={touched} />
                <Divider />
            </Stack>
        </Stack>
    );
};

export default OfferForm;
