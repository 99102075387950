import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Divider } from "@mui/material";
// components
import { PageTitle, RingsLoader } from "../../../components";
import { OfferForm } from "../../../sections";
import { errorAlert } from "../../../utils/alerts";
const Offers = () => {
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState([]);
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/gift-templates?sorts=-role_id`)
            .then((res) => {
                setOffers(res.data.data);
                setLoading(false);
            })
            .catch((error) => {
                errorAlert(error);
            });
    }, []);
    if (loading) return <RingsLoader />;
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="العروض و الهدايا" />
            <Stack gap="20px" width="100%" maxWidth="700px">
                {offers.map((offer) => (
                    <OfferForm
                        key={offer.id}
                        id={offer.id}
                        isDisabled={offer.is_disabled}
                        details={offer.details}
                        type={offer.type}
                        tripsGoal={offer.trips_goal}
                        amount={offer.amount}
                        validDuration={offer?.valid_duration?.split(" ")[0]}
                        expiresAt={offer.expires_at}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export default Offers;
