import React, { useState } from "react";
// react redux
import { useSelector } from "react-redux";
// mui
import {
    Box,
    Dialog,
    IconButton,
    DialogTitle,
    Stack,
    TextField,
    DialogContent,
    Typography,
} from "@mui/material";
// icons
import { FiFileText } from "react-icons/fi";
// comsponents
import { SubmitButton } from "..";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// xlsx

const DownloadCSV = ({ title, endpoint, downloadedFilename }) => {
    const userToken = useSelector((state) => state.userToken);

    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [error, setError] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function compareDates() {
        // Assuming date format is DD/MM/YYYY
        if (!startDate || !endDate) {
            setError("يجب ادخال تاريخ البدء و تاريخ الانتهاء");
            return;
        }
        var [year1, month1, day1] = startDate.split("-").map(Number);
        var [year2, month2, day2] = endDate.split("-").map(Number);

        // Months are 0-based in JavaScript, so subtract 1 from month
        var jsDate1 = new Date(year1, month1 - 1, day1);
        var jsDate2 = new Date(year2, month2 - 1, day2);

        if (jsDate1 < jsDate2) {
            setError("");
        } else if (jsDate1 > jsDate2) {
            setError("تاريخ الانتهاء يجب ان يكون بعد تاريخ البدء");
        } else {
            setError("تاريخ الانتهاء يجب ان يكون بعد تاريخ البدء");
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        compareDates();

        if (!error) {
            EnhancedAxios(null, userToken, "blob")
                .get(`${endpoint}?from=${startDate}&to=${endDate}`)
                .then(async (response) => {
                    console.log("response", response);
                    const blob = response.data;
                    const filename = `${downloadedFilename}&from=${startDate}&to=${endDate}.xlsx`;
                    downloadBlob(blob, filename);
                    handleClose();
                })
                .catch((error) => console.log("error", error));
        }
    };
    async function downloadBlob(blob, filename) {
        if ("download" in document.createElement("a")) {
            // Browser supports Blob download directly
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        } else {
            const reader = new FileReader();
            reader.onload = () => {
                const dataURI = reader.result;
                const link = document.createElement("a");
                link.href = dataURI;
                link.download = filename;
                link.click();
            };
            reader.readAsDataURL(blob);
        }
    }
    return (
        <Box>
            <IconButton
                onClick={handleClickOpen}
                sx={{ color: "primary.main" }}
            >
                <FiFileText />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{ minWidth: "300px" }}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent sx={{ minWidth: { xs: "260px", sm: "350px" } }}>
                    <Stack
                        component="form"
                        p={2}
                        width="100%"
                        gap={2}
                        onSubmit={(e) => handleSubmit(e)}
                    >
                        <TextField
                            label="من"
                            name="start_date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            type="date"
                        />
                        <TextField
                            label="الي"
                            name="end_date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            type="date"
                        />
                        <SubmitButton text="تحميل" />
                    </Stack>
                    <Typography color="error">{error}</Typography>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default DownloadCSV;
