import {
    set_user_token,
    remove_token_from_local,
    set_current_user_data,
} from "../actions/actionsType";

let intialState = {
    userToken: localStorage.getItem("userToken")
        ? JSON.parse(localStorage.getItem("userToken"))
        : null,
    userData: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : null,
};

const userReducer = (state = intialState, action) => {
    switch (action.type) {
        case set_user_token: {
            state.userToken = action.payload;
            localStorage.setItem("userToken", JSON.stringify(action.payload));
            return state;
        }
        case remove_token_from_local: {
            state.userToken = null;
            state.userData = null;
            localStorage.removeItem("userToken");
            return state;
        }
        case set_current_user_data: {
            state.userData = action.payload;
            localStorage.setItem("userData", JSON.stringify(action.payload));
            return state;
        }
        default: {
            return state;
        }
    }
};
export default userReducer;
