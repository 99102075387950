import React, { useState } from "react";
// mui components
import { Box, Pagination } from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

const TableGrid = ({
  rows,
  cols,
  itemsPerPage,
  totalItems,
  currentPage,
  setCurrentPage,
}) => {
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={Math.ceil(totalItems / itemsPerPage)}
        page={currentPage}
        defaultPage={1}
        onChange={(event, value) => {
          apiRef.current.setPage(value);
          setCurrentPage(value);
        }}
      />
    );
  }
  return (
    <Box className="datagrid" width="100%" sx={{ overflow: "auto" }}>
      <DataGrid
        pagination
        slots={{
          pagination: CustomPagination,
        }}
        autoHeight
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.id} // id for each row
        columns={cols} // columns fields
        rows={rows} //no of rows in data grid
        rowCount={totalItems} // how any items in api to calculate pages
        paginationMode="server"
        pageSizeOptions={[itemsPerPage]}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} : ${to} من ${count}`,
          },
          columnMenuSortAsc: "ترتيب تصاعدى",
          columnMenuSortDesc: " ترتيب تنازلى ",
          columnMenuFilter: "تصفية",
          columnMenuHideColumn: "اخفاء العمود ",
          columnMenuShowColumns: "اظهار الاعمدة",
          columnMenuUnsort: "كما هو",
          columnMenuLabel: "القائمة",
        }}
      />
    </Box>
  );
};

export default TableGrid;
