import { LinkButton } from "../../components";
import { Link } from "react-router-dom";
import {
    englishToArabic,
    translateToArabic,
} from "../translate/englishToArabic";
import moment from "moment";
export const ordersCols = [
    {
        field: "clientName",
        headerName: "اسم العميل",
        width: "120",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.customer?.name ?? "غير معروف",
    },
    {
        field: "clientPhone",
        headerName: "هاتف العميل",
        width: "150",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.customer?.phone ?? "غير معروف",
    },
    {
        field: "driverName",
        headerName: "اسم السائق",
        width: "120",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <Link to={`/edit-driver/${params.row?.driver?.id}`}>
                {params.row?.driver?.name ?? "غير معروف"}
            </Link>
        ),
    },
    {
        field: "driverPhone",
        headerName: "هاتف السائق",
        width: "150",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.driver?.phone ?? "غير معروف",
    },
    {
        field: "carType",
        headerName: "نوع السيارة",
        width: "110",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.car_type
                ? translateToArabic(params.row.car_type)
                : "غير معروف",
    },
    {
        field: "orderType",
        headerName: "نوع الطلب",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.type ? translateToArabic(params.row.type) : "غير معروف",
    },
    {
        field: "orderStatus",
        headerName: "حالة الطلب",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.status
                ? translateToArabic(params.row.status)
                : "غير معروف",
    },
    {
        field: "created_at",
        headerName: "تاريخ الانشاء",
        width: "160",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format(`YYYY/MM/DD - HH:mm`)
                : "لا يوجد",
    },
    {
        field: "showDetails",
        headerName: "مشاهدة",
        width: "100",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <LinkButton
                text="مشاهدة"
                to={`/order-details/${params.row?.id}`}
                size="small"
                variant="outlined"
            />
        ),
    },
];
