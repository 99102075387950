import React, { useState, useEffect } from "react";
// react router
import { useParams } from "react-router";
// react redux
import { useSelector } from "react-redux";
// axioss
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { Autocomplete, TextField, Box } from "@mui/material";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts";
// components
import SubmitButton from "../buttons/SubmitButton";
import { translateToArabic } from "../../utils/translate/englishToArabic";
const SelectRoles = ({ defaultValue }) => {
    const { userId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleAutocompleteChange = (e, newValue) => {
        setValue(newValue ?? null);
    };
    // get alll roles
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(
                `/admin/roles?sorts=-created_at&filters=name:nq:admin|super-admin|guest&page=1&paginate=999`
            )
            .then((res) => {
                setRoles(
                    res.data.data.map((item) => ({
                        id: item.id,
                        name: translateToArabic(item.name),
                    }))
                );
                // default values comes as and array of role_id
                // loop on the roles if any role.id is in the defaultvalue array
                // push this role to value of autocomplete
                res.data.data.forEach((role) => {
                    if (defaultValue.includes(role.id))
                        setValue((prev) => [
                            ...prev,
                            {
                                id: role.id,
                                name: translateToArabic(role.name) ?? role.name,
                            },
                        ]);
                });
                setLoading(false);
            })
            .catch((error) =>
                errorAlert(
                    "حدث خطا اثناء العرص التصنيفات برجاء اعادة تحميل الصفحة"
                )
            );
    }, []);
    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();

        setIsSubmitting(true);
        value.forEach((role) => {
            formData.append("roles", role.id);
        });
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/users/${userId}`, formData)
            .then((res) => {
                successAlert("تم تعديل دور المستخدم");
                setIsSubmitting(false);
            })
            .catch((error) => {
                errorAlert(error);
                setIsSubmitting(false);
            });
    };
    return (
        <Box display="flex" gap="10px" component="form" onSubmit={handleSubmit}>
            <Autocomplete
                multiple
                sx={{ width: "100%" }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                isOptionEqualToValue={(option, value) =>
                    option?.id == value?.id
                }
                getOptionLabel={(option) => option.name}
                options={roles}
                loading={loading}
                onChange={handleAutocompleteChange}
                value={value ?? null}
                // defaultValue={roles.map((item) => item.id == defaultValue)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="الدور"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <AiOutlineLoading3Quarters />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <SubmitButton text="تعديل" disabled={isSubmitting} />
        </Box>
    );
};

export default SelectRoles;
